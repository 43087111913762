import { EDUCATION_SECTION } from "~lib/constants";
import { ERROR_CODES } from "~lib/data/errorMessages";

const currentYear: number = new Date().getFullYear();
const minYear = 1970;

export const convertYear = (year: number, maxYear: number): number => {
  let selectedYear: number;
  if (!year) {
    selectedYear = currentYear;
  } else {
    const tempYear = parseInt(year.toString().padEnd(4, "0"));
    if (tempYear >= minYear && tempYear <= maxYear) {
      selectedYear = tempYear;
    } else {
      selectedYear = maxYear;
    }
  }

  return selectedYear;
};

export const convertDateToYearAndMonth = (
  value: number
): { year: number; month: number } => {
  let year: number;
  let month: number;
  if (!value) {
    return { year: null, month: null };
  } else {
    year = new Date(value + 86400000).getFullYear();
    month = new Date(value + 86400000).getMonth() + 1;
  }
  return { year, month };
};

export const getDateStamp = (value: string): number => {
  if (!value) {
    return NaN;
  }

  const date = value.split(/[ ]|\//);
  let month = date[0];
  const year = date[1];
  if (!year || year.length < 4) {
    return NaN;
  }

  if (month.length === 1) {
    month = "0" + month;
  }
  return new Date([year, month, 15].join("-")).getTime();
};

export const checkDateFormatIsError = (
  inputValue: string,
  id: string,
  sectionType: string
): string[] => {
  const isEducation: boolean = sectionType === EDUCATION_SECTION;
  const maxYear: number = isEducation ? currentYear + 16 : currentYear;

  if (!inputValue || (id === "endDate" && inputValue === "Present")) {
    return null;
  }
  let error = null;
  const { year, month } = convertDateToYearAndMonth(getDateStamp(inputValue));
  if (!month || !year) {
    error = [ERROR_CODES.DATE_ERROR];
  } else {
    if (year < minYear) {
      error = [ERROR_CODES.MINIMAL_DATE_ERROR];
    } else if (
      year > maxYear ||
      (year === maxYear && month > new Date().getMonth() + 1)
    ) {
      error = [ERROR_CODES.MAXIMAL_DATE_ERROR];
    }
  }
  return error;
};

export const convertToFullMonthName = (mon: string): string => {
  const monthNameObj = {
    Jan: "January",
    Feb: "February",
    Mar: "March",
    Apr: "April",
    May: "May",
    June: "June",
    July: "July",
    Aug: "August",
    Sep: "September",
    Oct: "October",
    Nov: "November",
    Dec: "December"
  };

  return monthNameObj[mon];
};

/**
 * Determine the current location based on the current time zone
 */
export const checkLocatedInEurope = (): boolean =>
  Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.includes("Europe");

import React from "react";

export const keepOnlyAlphaSpace = (str: string): string => {
  return str.replace(/[^a-zA-Z\s]*/g, "");
};

export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const startWithVowel = (str: string): boolean => {
  return str !== "" && "aeiou".includes(str.charAt(0).toLowerCase());
};

export const textOrEmpty = (text: string): string => {
  return text ? text : "";
};

export const processStringTag = (element: string): string => {
  if (!element) return "";
  const tempContainer = document.createElement("div");
  //Remove extra \n to solve the space problem when ul and ol are close together
  element = element
    ?.replaceAll("<br>", "\n")
    .replaceAll("<ol>\n", "<ol>")
    .replaceAll("</ol>\n", "</ol>")
    .replaceAll("<ul>\n", "<ul>")
    .replaceAll("</ul>\n", "</ul>");
  tempContainer.innerHTML = element;
  return tempContainer.innerText;
};

export const processFullContentStringTag = (
  element: string,
  isFullcontent = false
): string | React.ReactElement => {
  if (!element) return "";

  if (!isFullcontent) {
    return processStringTag(element);
  }

  // viewFullcontent modal needs available hyperlinks.
  const createMarkup = { __html: element };
  return React.createElement("div", {
    dangerouslySetInnerHTML: createMarkup
  });
};
